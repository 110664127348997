<template>
  <div></div>
</template>

<script>
import { EventBus } from '../eventBus/event-bus'
import { mapActions, mapGetters } from 'vuex'
import checkinProvider from '../providers/checkin'
import eventProvider from '../providers/event'
import matchProvider from '../providers/match'

// const CheckinItem = () => import(/* webpackChunkName: "EventDetail" */ '@/components/CheckinItem')
// const EventMetaStats = () => import(/* webpackChunkName: "EventDetail" */ '@/components/partial/EventMetaStats')
// const MatchItem = () => import(/* webpackChunkName: "EventDetail" */ '@/components/MatchItem')
// const CheckinsOverview = () => import(/* webpackChunkName: "EventDetail" */ '@/components/CheckinsOverview')
// const CheckinProcessDrawer = () => import(/* webpackChunkName: "EventDetail" */ '@/components/CheckinProcessDrawer')
// const EditEvent = () => import(/* webpackChunkName: "EventDetail" */ '@/components/events/EditEvent')
// const EventManage = () => import(/* webpackChunkName: "EventDetail" */ '@/components/events/EventManage')

export default {
  name: 'event-detail',
  components: {
    // 'event-meta-stats': EventMetaStats,
    // 'match-item': MatchItem,
    // 'checkin-item': CheckinItem,
    // 'checkins-overview': CheckinsOverview,
    // 'checkin-process-drawer': CheckinProcessDrawer,
    // 'edit-event': EditEvent,
    // EventManage
  },

  props: {
    pageView: {
      type: String,
      default: 'detail'
    }
  },

  data() {
    return {
      BASE_URL: process.env.VUE_APP_BASE_URL,
      checkins: [],
      matches: [],
      eventId: Number(this.$route.params.eventId),
      event: null,
      eventState: null,
      isLoading: true,
      eventIsPast: false,
      // organizer: null,
      randomCheckins: [],
      showCopyEvent: false,
      showEditEvent: false,
      showEventCheckins: false,
      showEventDetail: true,
      showEventDashboard: false,
      openingHours: {
        NrOfLocations: 0,
        MinTimeOpenTimeStamp: 0,
        MaxTimeCloseTimeStamp: 0,
        MaxTimeClose:
          this.$options.filters.dateObjectIsoDateString(new Date()) +
          'T13:00:00',
        MinTimeOpen:
          this.$options.filters.dateObjectIsoDateString(new Date()) +
          'T13:00:00',
        MaxMinutes: 0,
        MinMinutes: 0
      },
      toastTimer: null,
      showToast: false
    }
  },

  computed: {
    // ...mapGetters({
    //   profile: 'getProfile',
    //   activeDrawerType: 'getActiveDrawerType',
    //   isLoggedIn: 'getIsLoggedIn'
    // }),

    // hasEventRights() {
    //   if (!this.isLoggedIn || !this.eventState) {
    //     return false
    //   }
    //   return this.eventState.CanEdit
    // },

    // eventName() {
    //   return this.event ? this.event.Name : ''
    // },

    // showSupriseMeMatches() {
    //   return this.isLoggedIn && this.checkins.length > 10 && this.matches.length === 0 && (this.eventState && this.eventState.ProfileCheckedIn > 0)
    // },

    // date() {
    //   let strDate = this.$options.filters.dateFormat(this.event.StartDate, 'en', '', 'dd MMM yy')
    //   let endDate = this.$options.filters.dateFormat(this.event.EndDate, 'en', '', 'dd MMM yy')
    //   if (strDate !== endDate) {
    //     strDate += ' - ' + endDate
    //   }
    //   return strDate
    // },

    // time() {
    //   let strTime = ''
    //   if (this.event.StartMinutes === 0 && (this.event.EndMinutes === 0 || this.event.EndMinutes === 1440)) {
    //     return ''
    //   }
    //   strTime = this.$options.filters.minutesToTime(this.event.StartMinutes)
    //   if (this.event.EndMinutes !== this.event.StartMinutes) {
    //     strTime += ' - ' + this.$options.filters.minutesToTime(this.event.EndMinutes)
    //   }
    //   return strTime
    // },

    // eventDescription() {
    //   return this.event ? this.event.Description : ''
    // },

    // eventImage() {
    //   return this.event ? this.$options.filters.getEventImageSrc(this.event.Image) : ''
    // },

    // organizer() {
    //   if (this.event && !this.event.Organizers.length) { return null }
    //   let organizer = this.event.Organizers.find(o => o.IsPrimary === true)
    //   if (typeof organizer === 'undefined') {
    //     organizer = this.event.Organizers[0]
    //   }
    //   return organizer.Name !== '' ? organizer : null
    // },

    // isRegistered() {
    //   return this.eventState
    //     && (this.eventState.Registration !== null && this.eventState.Registration.RegistrationStatus === 'Final')
    //     && !this.eventIsPast
    // },

    // organizerImage() {
    //   if (this.organizer === null) { return require('@/assets/img/defaultProfileImage_v2.png') }
    //   if (this.organizer.ProfileId) {
    //     // Get profile image
    //     return this.$options.filters.getProfileImage(this.organizer.Image, this.organizer.ProfileId, 84)
    //   }
    //   if (this.organizer.ProfileId === 0 && this.organizer.Image) {
    //     return this.$options.filters.getEventImageSrc(this.organizer.Image, 160)
    //   }
    //   return require('@/assets/img/defaultProfileImage_v2.png')
    // },

    // hasAddress() {
    //   let self = this
    //   let hasAddress = false
    //   let e = self.event

    //   if (e) {
    //     if (
    //       e.LocationName.length +
    //       e.Address.length +
    //       e.Postalcode.length +
    //       e.City.length +
    //       e.Country.length
    //     ) {
    //       hasAddress = true
    //     }
    //   }

    //   return hasAddress
    // },

    // hasEventAccess() {
    //   let self = this
    //   let hasAccess = false
    //   if (
    //     self.profile !== null &&
    //     self.event !== null &&
    //     self.profile.Id === self.event.CreatedBy
    //   ) {
    //     hasAccess = true
    //   }
    //   return hasAccess
    // }
  },

  // watch: {
  //   '$route.name': function() {
  //     this.forceRerender()
  //   }
  // },

  async created() {
    this.isLoading = true
    await this.getEventData()

    window.location.replace(process.env.VUE_APP_S2M_BASE_URL + '/events/' + this.eventId + '/' + this.$options.filters.convertToSlug(this.event.Name))
    // await this.getEventState()


    // this.isLoading = false

    // if (this.isLoggedIn) {
    //   await this.getEventCheckins()
    // }

    // self.setVisbleItem(self.pageView)

    // EventBus.$on('checkinSucceeded', checkin => {
    //   self.getEventState()
    //   self.getEventCheckins()
    // })

    // EventBus.$on('passingMatches', matches => {
    //   self.matches = matches
    // })

    // EventBus.$on('eventSaved', eventObj => {
    //   if (self.event.Id === eventObj.Id) {
    //     self.event = eventObj
    //   }
    // })
  },

  beforeDestroy() {
    EventBus.$off('checkinSucceeded')
    EventBus.$off('eventSaved')
    EventBus.$off('passingMatches')
    this.getPassportState()
  },

  methods: {
    // ...mapActions(['getPassportState']),

    // copyUrl() {
    //   let self = this
    //   var tempInput = document.createElement('input')
    //   tempInput.value = this.BASE_URL + '/events/' + this.eventId
    //   document.body.appendChild(tempInput)
    //   tempInput.select()
    //   document.execCommand('copy')
    //   document.body.removeChild(tempInput)

    //   if (this.toastTimer) {
    //     clearTimeout(this.toastTimer)
    //   }
    //   this.showToast = true
    //   this.toastTimer = setTimeout(() => {
    //     self.showToast = false
    //     clearTimeout(this.toastTimer)
    //   }, 2000)
    // },

    // checkIfEventIsPast() {
    //   let dayDiff = this.$options.filters.daysBetweenCurrentDate(
    //     new Date(this.event.StartDate)
    //   )
    //   let currentMinutes = this.$options.filters.timeToMinutes(new Date())
    //   if (dayDiff === 0 && this.event.EndMinutes < currentMinutes) {
    //     return true
    //   }
    //   return false
    // },

    // forceRerender() {
    //   this.$nextTick().then(() => {
    //     this.setVisbleItem(this.pageView)
    //   })
    // },

    // /**
    //  * Get last checkins of array
    //  */
    // getLastCheckins(nr = 0) {
    //   let arr = this.checkins
    //   if (arr.length <= nr) {
    //     return arr
    //   } else {
    //     return arr.slice(0, nr)
    //   }
    // },

    // getCheckinMatches(WorkingOn, page, itemsPerPage, checkinId) {
    //   try {
    //     let response = matchProvider.methods.getMatches(WorkingOn, 0, 0, page, itemsPerPage, '0', 0, this.eventId, 0, checkinId)
    //     if (response.status === 200) {
    //       this.matches = response.data.Results
    //     }
    //   }
    //   catch { }
    // },

    // getRandomCheckins() {
    //   let self = this
    //   let output = []
    //   let data = this.$options.filters.getRandom(this.checkins, 3)
    //   data.map(checkin => {
    //     if (checkin.Id !== self.eventState.ProfileCheckedIn) {
    //       output.push(checkin)
    //     }
    //   })
    //   this.randomCheckins = output
    // },

    // contactOrganizor(organizer) {
    //   if (organizer.ProfileId === 0 || !this.isLoggedIn) {
    //     window.location.href = 'mailto:' + organizer.Email
    //     return
    //   }
    //   this.$store.commit('setSelectedPublicProfile', {
    //     profileId: organizer.ProfileId,
    //     view: 'detail'
    //   })
    // },

    // showCheckinProcessDrawer() {
    //   // Push GA event
    //   this.$googleAnalytics.pushEvent('event description', 'button', 'going')

    //   // Show drawer
    //   this.$store.commit('setActiveDrawerType', 'checkinProcessDrawer')
    // },

    // executeFinishedActions() {
    //   this.getEventState()
    //   this.getEventCheckins()
    // },

    async getEventData() {
      let self = this

      await eventProvider.methods
        .getEventById(self.eventId)
        .then(response => {
          if (response.status === 200) {
            self.isLoading = false

            self.event = response.data
            self.event.Name = self.event.Name
            self.event.Description = self.event.Description
            self.event.Tags = self.event.Tags
            self.openingHours.MinTimeOpenTimeStamp = 0
            self.openingHours.MaxTimeCloseTimeStamp = 0
            self.openingHours.MaxTimeClose = self.event.StartDate
            self.openingHours.MinTimeOpen = self.event.EndDate
            self.openingHours.MaxMinutes = self.event.EndMinutes
            self.openingHours.MinMinutes = self.event.StartMinutes

            // self.eventIsPast = self.checkIfEventIsPast()
          }
        })
        .finally(() => {
          // self.isLoading = false
        })
    },

  //   async getEventState() {
  //     let self = this
  //     await eventProvider.methods.getEventState(self.eventId).then(response => {
  //       if (response.status === 200) {
  //         self.eventState = response.data
  //         if (!self.isLoggedIn) {
  //           self.eventState.ProfileCheckedIn = 0
  //         }
  //       }
  //     })
  //   },

  //   async getEventCheckins() {
  //     let self = this
  //     let searchTerm = ''
  //     let page = 0
  //     let itemsPerPage = 0

  //     checkinProvider.methods
  //       .getEventCheckins(this.eventId, searchTerm, page, itemsPerPage)
  //       .then(response => {
  //         if (response.status === 200) {
  //           // response.data.Results
  //           self.checkins = this.$objectHelper.sortDecending(response.data.Results, 'Id')

  //           let checkin = self.checkins.filter(c => c.ProfileKey === self.profile.Key && c.HasLeft === false)

  //           if (checkin.length > 5) {
  //             // self.getCheckinMatches(checkin[0].WorkingOn, 1, 3, checkin[0].Id)
  //           }
  //         }
  //       })
  //   },

  //   checkout() {
  //     // Push GA event
  //     this.$googleAnalytics.pushEvent('event description', 'button', 'leaving')

  //     if (this.eventState.Registration === null) { return }

  //     eventProvider.methods.cancelEventRegistration(this.eventState.Registration.Id)
  //       .then(() => {
  //       })
  //       .catch(() => {
  //       })
  //       .finally(() => {
  //         this.matches = []
  //         this.getEventState()
  //         this.getEventCheckins()
  //       })

  //     // checkinProvider.methods
  //     //   .updateCheckinLeftState(this.eventState.ProfileCheckedIn)
  //     //   .then(response => {
  //     //     this.eventState.ProfileCheckedIn = 0
  //     //     this.getEventState()
  //     //     this.getEventCheckins()
  //     //     this.matches = []
  //     //   })
  //     //   .catch(() => {
  //     //     this.eventState.ProfileCheckedIn = 0
  //     //     this.getEventState()
  //     //     this.getEventCheckins()
  //     //     this.matches = []
  //     //   })
  //   },

  //   setShowCopyEvent() {
  //     this.setVisbleItem('copy')
  //   },

  //   setShowEditEvent() {
  //     if (this.eventState.CanEdit) {
  //       this.$router.push({ name: 'EditEventDetail' })
  //       this.setVisbleItem('edit')
  //     } else {
  //       this.setShowEventDetail()
  //     }
  //   },

  //   setShowEventCheckins() {
  //     this.$router.push({ name: 'EventAttendees' })
  //     this.setVisbleItem('checkins')
  //   },

  //   setShowEventDetail() {
  //     this.$router.push({ name: 'EventDetail' })
  //     this.setVisbleItem('detail')
  //   },

  //   setShowEventDashboard() {
  //     if (this.hasEventRights) {
  //       this.$router.push({ name: 'EventDashboard' })
  //       this.setVisbleItem('dashboard')
  //     } else {
  //       this.setShowEventDetail()
  //     }
  //   },

  //   setShowLoading() {
  //     this.loading = !this.loading
  //   },

  //   setVisbleItem(item) {
  //     this.showCopyEvent = item === 'copy'
  //     this.showEventCheckins = item === 'checkins'
  //     this.showEditEvent = item === 'edit'
  //     this.showEventDetail = item === 'detail'
  //     this.showEventDashboard = item === 'dashboard'
  //   }
  }
}
</script>

<style lang="scss" scoped>
// .navbar-custom-menu {
//   overflow: hidden;
//   overflow-x: auto;
// }
// ::v-deep .info-explanation {
//   display: none;
// }

// ::v-deep .tagsarea.textarea {
//   margin-bottom: 0 !important;
// }

// ::v-deep .forminput-terms {
//   margin-bottom: $gap;
// }

// ::v-deep .wrapper-noAuthLogin {
//   .columns {
//     margin: -#{$gap / 2};
//     .column {
//       padding: #{$gap / 2};
//     }
//   }
// }

// .box-event-detail {
//   width: 100%;
//   height: 100%;
//   flex-direction: column;

//   .details {
//     flex: 1 0;
//     .block {
//       img {
//         width: 48px;
//       }

//       .metaStats {
//         ::v-deep .icon {
//           display: none;
//         }
//       }
//     }
//   }
// }

// .column-stats-wrapper {
//   button {
//     width: 100%;
//     max-width: 300px;
//     justify-content: center;
//   }
//   .button-column-wrapper {
//     display: flex;
//     // align-items: center;
//   }
//   .column-share {
//     .title {
//       margin-bottom: 5px;
//     }
//     .text {
//       word-wrap: break-word;
//     }
//   }
//   @media screen and (max-width: 419px) {
//     .column {
//       width: 100%;
//     }
//   }
// }

// .button-ticket {
//   width: 100%;
// }

// .column-address {
//   .addressWrapper {
//     display: flex;
//     .font-icon {
//       font-size: 3rem;
//       line-height: 0;
//     }
//   }
// }

// .tag {
//   max-width: 100%;
//   .text {
//     display: block;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }
// }

// .tags-suggestions-wrapper {
//   .row {
//     border-bottom: 1px solid $grey-light;
//     position: relative;
//     padding: 10px 0 10px 30px;
//     a {
//       position: absolute;
//       left: 0;
//     }
//   }
// }

// .toast {
//   position: fixed;
//   left: 0;
//   bottom: 30px;
//   text-align: center;
//   width: 100vw;
//   .box {
//     display: inline-block;
//   }
// }
</style>
